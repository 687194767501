
import { Options, Vue } from 'vue-class-component';
import ContactUsHero from '@/components/ContactUsHero.vue';
import FindOutAboutUS from '@/components/FindOutAboutUS.vue';

@Options({
  components: {
    ContactUsHero,
    FindOutAboutUS,
  },
})
export default class Contact extends Vue {}
